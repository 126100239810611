export default class Player {
  colors: Array<string>;
  codeLen: number;
  isAllowBlanks: boolean;
  isAllowDuplicates: boolean;
  constructor () {
    this.colors = []
    this.codeLen = 3
    this.isAllowBlanks = false
    this.isAllowDuplicates = false
  }

  setColors (colors: Array<string> = []) {
    if (colors.length > 3) {
      this.colors = colors
    }
  }

  setCodeLen (len = 4) {
    if (len > 2) {
      this.codeLen = len
    }
  }

  setDuplicates (isAllowDuplicates = false) {
    this.isAllowDuplicates = isAllowDuplicates
  }

  getRandom (max: number) {
    return Math.floor(Math.random() * Math.floor(max))
  }
}

<template>
  <div>
    Liar
  </div>
</template>

<script>
export default {
  name: 'Liar'
}
</script>

import { actions } from './constants'
import User from '../services/interfaces/User'

export default {
  state: {
    name: null,
    users: []
  },

  actions: {
    [actions.user.name] ({ commit }, userName: string) {
      commit(actions.user.name, userName)
    },

    [actions.user.online] ({ commit }, users: Array<User>) {
      commit(actions.user.online, users)
    }
  },

  mutations: {
    [actions.user.name] (state, userName: string) {
      state.name = userName
    },

    [actions.user.online] (state, users: Array<User>) {
      state.users = users
    }
  }
}

import Card from '../interfaces/Card'

const createArray = (n) => {
  return Array.from(Array(n).keys())
}

const getCard = (identifier: string, suit: string): Card => {
  return {
    identifier,
    suit
  }
}

export class CardsPack {
  cards: Array<Card>
  total: number
  suits: Array<string>

  constructor () {
    this.total = 1
    this.cards = []
    this.suits = ['club', 'diamond', 'heart', 'spade']
  }

  setTotalPacks (packs: number) {
    this.total = packs
  }

  generateCardsForSuit (suit: string): Array<Card> {
    const cards = createArray(9).map((a, n) => getCard((n + 2).toString(), suit))
    cards.push(getCard('jack', suit))
    cards.push(getCard('queen', suit))
    cards.push(getCard('king', suit))
    cards.push(getCard('ace', suit))
    return cards
  }

  setCards () {
    this.cards = []
    createArray(this.total).forEach(() => {
      this.suits.forEach(suit => {
        this.cards = this.cards.concat(this.generateCardsForSuit(suit))
      })
    })
  }

  getAllCards (): Array<Card> {
    return this.cards
  }

  fetchCards (total: number): Array<Card> {
    return this.cards.splice(0, total)
  }

  shuffleCards () {
    let currentIndex = this.cards.length
    let temporaryValue
    let randomIndex = -1

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = this.cards[currentIndex]
      this.cards[currentIndex] = this.cards[randomIndex]
      this.cards[randomIndex] = temporaryValue
    }
  }
}

<template>
<span>
  <component
    v-if="component"
    :is="component"
    :fill1="fill1"
    :fill2="fill2"
    :fill3="fill3"
    :stroke1="stroke1"
    :width="width"
    :top="top"
    :left="left"></component>
</span>
</template>

<script>
export default {
  name: 'ThemeItem',

  data () {
    return {
      component: null
    }
  },

  props: {
    theme: {
      type: String,
      required: true
    },

    item: {
      type: String,
      required: true
    },

    width: {
      type: Number,
      required: false
    },

    top: {
      type: Number,
      required: false
    },

    left: {
      type: Number,
      required: false
    },

    fill1: {
      type: String,
      required: false
    },

    fill2: {
      type: String,
      required: false
    },

    fill3: {
      type: String,
      required: false
    },

    stroke1: {
      type: String,
      required: false
    }
  },

  computed: {
    loader () {
      // return () => import('./themes/christmas/reindeer.vue')
      return () => import(`./themes/${this.theme}/${this.item}.vue`)
    }
  },

  mounted () {
    this.loader().then(() => {
      this.component = () => this.loader()
    })
  }
}
</script>

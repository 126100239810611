<template>
<div  class="channel-link pos-rel">
  <slot name="greeting">
    <div class="m-b-sm">
      ⭐Hey {{ userName }}, You are the <strong>Host</strong>.
    </div>
  </slot>
  <slot name="action"></slot>
  <div class="pos-rel m-b-sm">
    <div class="m-b-sm">
      <slot name="linkMessage">
        Pass this link to other players before clicking Ready:
      </slot>
    </div>
    <div>
      <span @click="copyToClipboard(gameLink)" class="clickable pos-rel">
        <strong class="link">{{ gameLink }}</strong> ⎘
        <transition name="fade">
          <span v-if="isCopiedToClipboard" class="copy-msg">Copied to clipboard</span>
        </transition>
      </span>
    </div>
  </div>
  <div>
    <slot name="message"></slot>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'channelLinkMessage',

  data () {
    return {
      isCopiedToClipboard: false
    }
  },

  props: {
    gameLink: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState({
      userName: state => state.user.name
    })
  },

  methods: {
    copyToClipboard (text) {
      const textarea = document.createElement('textarea')
      textarea.value = text
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.isCopiedToClipboard = true
      setTimeout(() => {
        this.isCopiedToClipboard = false
      }, 1000)
    }
  }
}
</script>

import Player from './Player'
import MoveResult from '../interfaces/MoveResult'

export class CodeMaker extends Player {
  code: Array<string>
  constructor () {
    super()
    this.code = []
  }

  generateCode () {
    this.code = []
    const addRandomColor = () => {
      const color = this.colors[this.getRandom(this.colors.length - 1)]
      if (!this.isAllowDuplicates && this.code.includes(color)) {
        addRandomColor()
      } else {
        this.code.push(color)
      }
    }
    Array.from(Array(this.codeLen)).forEach(() => {
      addRandomColor()
    })
    return this.code
  }

  setCode (code: Array<string> = []) {
    if (code.length === this.codeLen && code.filter(color => !this.colors.includes(color)).length === 0) {
      this.code = code
    }
  }

  getCode () {
    return this.code
  }

  checkCode (inputCode: Array<string> = []): MoveResult {
    let totalCorrectPlaces = 0
    let totalInCorrectPlaces = 0
    if (this.code && this.code.length === inputCode.length) {
      totalCorrectPlaces = this.code.filter((color, index) => inputCode[index] === color).length
      totalInCorrectPlaces = this.code.filter((color, index) => inputCode[index] !== color && inputCode.includes(color)).length
    }
    return {
      CorrectPlaces: totalCorrectPlaces,
      IncorrectPlaces: totalInCorrectPlaces,
      Move: inputCode
    }
  }
}

import Axios from 'axios'
import { mapState } from 'vuex'
import UserNameBox from '@/components/shared/UserNameBox'
import ChannelLinkMessage from '@/components/shared/ChannelLinkMessage'

export default {
  data () {
    return {
      currentChannel: null,
      game: null,
      gameUsers: [],
      isInitiator: false,
      isGameReady: false,
      loading: false,
      isSinglePlayer: true,
      flashMessage: null,
      screenScaleFactor: 1,
      maxAllowedPlayers: null,
      minAllowedPlayers: 1
    }
  },

  components: {
    UserNameBox,
    ChannelLinkMessage
  },

  watch: {
    '$route.query' () {
      if (this.currentChannel && !this.$route.query.channel) {
        this.onUserExit()
        this.currentChannel = null
        this.isGameReady = false
      }
    }
  },

  computed: {
    ...mapState({
      userName: state => state.user.name,
      onlineUsers: state => state.user.users
    }),

    isSettingsDone () {
      if (this.isSinglePlayer) {
        return this.isInitiator
      }
      return !!this.currentChannel
    },

    gameLink () {
      if (!this.currentChannel) {
        return
      }
      return `${window.location.origin}/${this.game}?channel=${this.currentChannel}`
    },

    isMobile () {
      return window.matchMedia('only screen and (max-width: 760px)').matches
    }
  },

  methods: {
    generateArray (num) {
      if (isNaN(num)) {
        return []
      }
      return Array.from(Array(parseInt(num)).keys())
    },

    readyTheGame () {
      this.isGameReady = true
      this.sendGameReadyEvent()
    },

    kickOffGame () {
      if (this.isSinglePlayer) {
        this.isInitiator = true
        this.readyTheGame()
        return
      }
      this.isGameReady = false
      Axios.post('/channel/create', {
        game: this.game
      }).then(response => {
        this.isInitiator = true
        this.addUserToChannel(response.data)
        this.changeGameRoute()
        this.setChannelEvents()
      }).catch(() => {
        this.isInitiator = true
        this.addUserToChannel('RandomString')
        this.changeGameRoute()
      })
    },

    setFlashMessage (isBravoMessage = true) {
      this.flashMessage = isBravoMessage
        ? this.pickRandom(['Good job mate!', 'You are awesome!', 'You are doing great!'])
        : this.pickRandom(['It\'s okay!', 'Better luck next time!', 'You can do it!', 'Try again!', 'It happens!'])
      setTimeout(() => {
        this.flashMessage = null
      }, 100)
    },

    changeGameRoute () {
      this.$router.push({
        name: this.$route.name,
        query: {
          channel: this.currentChannel
        }
      })
    },

    verifyChannel (channel) {
      return Axios.get(`/channel/verify?channel=${channel}`)
    },

    addUserToChannel (channel) {
      this.currentChannel = channel
      Axios.get(`/users?channel=${this.currentChannel}`).then((response) => {
        this.gameUsers = response.data
        if (this.shouldUserBeAllowedOnChannel(this.gameUsers)) {
          this.$socket.emit('add-user-to-channel', {
            channel: this.currentChannel,
            user: this.userName,
            game: this.game,
            isInitiator: this.isInitiator,
            meta: this.getUserMetaData()
          })
          this.onUserAddedToChannel()
        } else {
          alert('This game already has required number of players. Sorry!!!')
          this.$router.push({
            name: 'Home'
          })
        }
      })

      this.$socket.on(`users-${this.currentChannel}`, (data) => {
        this.gameUsers = data
      })
    },

    onUserAddedToChannel () {
      console.log('User got added to channel')
    },

    getUserMetaData () {
      return null
    },

    shouldUserBeAllowedOnChannel () {
      return this.isInitiator || this.maxAllowedPlayers === null || this.gameUsers.length < this.maxAllowedPlayers
    },

    pickANumber (max) {
      return Math.floor(Math.random() * max)
    },

    pickRandom (arr) {
      const index = Math.ceil(Math.random() * arr.length - 1)
      return arr[index]
    },

    setChannelDeleteEvent () {
      this.$socket.on(`channel-deleted-${this.currentChannel}`, () => {
        alert('Host has terminated the game.')
        this.isGameReady = false
        this.currentChannel = null
        this.$router.replace({
          name: this.$route.name
        })
      })
    },

    onUserExit () {
      if (this.isInitiator && this.currentChannel) {
        this.$socket.emit('delete-channel-notify', this.currentChannel)
      }
    },

    setupPageUnloadEvent () {
      window.addEventListener('beforeunload', () => {
        this.onUserExit()
      })
    },

    setScreenScaleFactor () {
      if (screen.width < 500) {
        this.screenScaleFactor = 0.5
      } else if (screen.width < 1200) {
        this.screenScaleFactor = 0.8
      } else {
        this.screenScaleFactor = 1
      }
    },

    sendGameReadyEvent () {
      this.$socket.emit('game-ready-on-channel', {
        channel: this.currentChannel
      })
    },

    receiveGameReadyEvent () {
      this.$socket.on(`game-ready-${this.currentChannel}`, () => {
        this.isGameReady = true
      })
    },

    broadcastToChannel (data) {
      data.channel = this.currentChannel
      this.$socket.emit('broadcast-to-channel', data)
    },

    receiveBroadcastOnChannel () {
      this.$socket.emit(`broadcast-on-${this.currentChannel}`, () => {
        console.log('Message broadcasted')
      })
    }
  },

  beforeDestroy () {
    this.onUserExit()
  },

  mounted () {
    if (!this.game) {
      return
    }
    this.setupPageUnloadEvent()
    this.setScreenScaleFactor()
    if (this.$route.query.channel) {
      this.loading = true
      this.verifyChannel(this.$route.query.channel).then((data) => {
        if (data.data.isGameReady) {
          this.isGameReady = true
        }
        this.addUserToChannel(this.$route.query.channel)
        this.setChannelDeleteEvent()
        this.setChannelEvents()
        this.isSinglePlayer = false
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$router.replace({
          name: this.$route.name,
          query: {}
        })
      })
    }
  }
}

<template>
  <div class="cards">
    <user-name-box :channel="currentChannel"></user-name-box>
    <!-- Introduction -->
    <transition name="fade" v-if="!loading && !currentChannel">
      <div class="game-body">
        <div class="m-b-md cover-page">
          <img src="/images/table/charcoal.jpg" alt="Cover page">
        </div>
        <div class="game-setting-wrapper pos-rel">
          <div class="game-name">Turth or Dare</div>
          <p>
            This game involves each player being asked if they would prefer to answer a truth question, or do a dare.
            Once they are picked, if they then refuse to answer the question or do the dare, they need to take a shot.
          </p>
          <button class="btn btn-primary btn-lg btn-block" @click="kickOffGame">
            Let's play
          </button>
        </div>
      </div>
    </transition>

    <!-- Set the game rules -->
    <transition name="fade" v-if="!loading && currentChannel && !isGameReady">
      <div class="game-body">
        <div class="m-b-md cover-page">
          <img src="/images/table/charcoal.jpg" alt="Cover page">
        </div>
        <div>
          <channel-link-message
            v-if="isInitiator"
            :game-link="gameLink">
            <div slot="greeting" class="m-b-sm">
              Ready to play truth and dare?
            </div>
            <div  v-if="gameUsers.length > 1" slot="message">
              {{ gameUsers.length }} players in the game so far...
            </div>
          </channel-link-message>
          <div v-if="isInitiator" class="pos-rel">
            <button
              class="btn btn-primary btn-lg btn-block"
              :disabled="minAllowedPlayers > gameUsers.length"
              @click="readyTheGame">Ready</button>
          </div>
          <div v-if="!isInitiator" class="game-setting-wrapper">
            <div class="game-name">Truth or Dare</div>
            <p>
              Game is about to start.
            </p>
          </div>
        </div>
      </div>
    </transition>

    <!-- Game ready -->
    <transition name="fade" v-if="!loading && currentChannel && isGameReady">
      <div class="game-body">
        <div class="m-b-md cover-page">
          <img src="/images/table/charcoal.jpg" alt="Cover page">
        </div>
        <!-- players' round table -->
        <div class="players-table">
          <div
            v-for="(player, index) in gameUsers"
            :key="`${player.name}+${index}`"
            :style="playerPositionStyle(index, gameUsers.length)">
            <player
              :rotation="`${getPlayerAngle(index, gameUsers.length)}deg`"
              :width="playerSize"
              :height="playerSize"
              :name="player.socketId && player.socketId === $socket.id ? 'You' : player.name"
              :fill="player.socketId === winnerId ? '#9bcb00' : '#3e464f'">
            </player>
          </div>
          <div
            v-if="gameUsers.length > 0"
            :style="getCenterPosition(100)"
            class="clickable"
            @click="beginRandomRotation">
            <bottle
              :style="rotationStyle"></bottle>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GameMixin from '@/components/assist/game_mixin.js'
import CircleSittingMixin from '@/components/assist/circle_sitting_mixin'
import Player from '@/components/shared/Player'
import Bottle from './Bottle'

export default {
  name: 'truthdare',

  components: {
    Player,
    Bottle
  },

  mixins: [
    GameMixin,
    CircleSittingMixin
  ],

  data () {
    return {
      isSinglePlayer: false,
      isShowModal: false,
      game: 'truthdare',
      winnerId: null,
      maxAllowedPlayers: 10,
      rotationStyle: null,
      minAllowedPlayers: 2,
      isRotating: false
    }
  },

  computed: {
    bottleAngles () {
      return this.getPlayersAngles(this.gameUsers.length)
    }
  },

  methods: {
    setChannelEvents () {
      if (!this.currentChannel) {
        return
      }
      this.receiveGameReadyEvent()
      this.receiveBroadcastOnChannel()
    },

    readyTheGame () {
      this.isGameReady = true
      this.sendGameReadyEvent()
      this.broadcastToChannel({
        winnerId: this.$socket.id
      })
    },

    beginRandomRotation () {
      if (this.isRotating) {
        return
      }
      const spins = this.pickRandom([3, 4, 5])
      let allPositions = this.generateArray(this.gameUsers.length)
      if (this.winnerId) {
        const positionsWithoutCurrentWinner = allPositions.filter(position => this.gameUsers[position].socketId !== this.winnerId)
        allPositions = allPositions.concat(positionsWithoutCurrentWinner)
        allPositions = allPositions.concat(positionsWithoutCurrentWinner)
        allPositions = allPositions.concat(positionsWithoutCurrentWinner)
      }
      const chosenOne = this.pickRandom(allPositions)
      const angle = (spins * 360) + this.bottleAngles[chosenOne]
      this.broadcastToChannel({
        rotationAngle: angle,
        winnerId: this.gameUsers[chosenOne].socketId
      })
    },

    receiveBroadcastOnChannel () {
      this.$socket.on(`broadcast-on-${this.currentChannel}`, (data) => {
        if (data.rotationAngle) {
          this.setInitialPosition()
          setTimeout(() => {
            this.rotationStyle = {
              transform: `rotate(${data.rotationAngle}deg)`,
              transition: 'transform 1.5s ease-out'
            }
            this.isRotating = true
            setTimeout(() => {
              this.isRotating = false
              if (data.winnerId) {
                this.setWinner(data.winnerId)
              }
            }, 1500)
          }, 100)
        } else {
          if (data.winnerId) {
            this.setWinner(data.winnerId)
          }
        }
      })
    },

    setWinner (winnerId) {
      this.winnerId = winnerId
    },

    onUserExit () {
      if (this.isInitiator) {
      }
    },

    setInitialPosition () {
      if (this.bottleAngles.length) {
        this.rotationStyle = {
          transform: `rotate(${this.bottleAngles[0]}deg)`
        }
      }
    }
  },

  mounted () {
    this.setInitialPosition()
  }
}
</script>

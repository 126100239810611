<template>
  <div class="game-body" ref="body">
    <div class="ouija-board">
      <img src="/images/ouija/ouija.png" alt="" class="board">
      <img src="/images/ouija/planchette.png" alt="" class="planchette" ref="planchette">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ouija',

  data () {
    return {
      keysMap: {
        a: '39%,21%,-44deg',
        b: '33%,25%,-44deg',
        c: '28%,29%,-44deg',
        d: '27%,31%,-29deg',
        e: '25%,36%,-24deg',
        f: '23%,40%,-15deg',
        g: '23%,44%,-3deg',
        h: '23%,49%,4deg',
        i: '23%,53%,16deg',
        j: '26%,56%,27deg',
        k: '29%,60%,32deg',
        l: '33%,65%,38deg',
        m: '39%,68%,42deg',
        n: '58%,24%,-56deg',
        o: '52%,25%,-50deg',
        p: '48%,28%,-41deg',
        q: '46%,32%,-37deg',
        r: '42%,36%,-27deg',
        s: '41%,40%,-15deg',
        t: '41%,44%,-3deg',
        u: '41%,49%,8deg',
        v: '41%,53%,20deg',
        w: '44%,58%,24deg',
        x: '49%,61%,39deg',
        y: '54%,65%,39deg',
        z: '57%,67%,51deg',
        1: '64%,25%,0deg',
        2: '64%,28%,0deg',
        3: '64%,32%,0deg',
        4: '64%,37%,0deg',
        5: '64%,41%,0deg',
        6: '64%,45%,0deg',
        7: '64%,49%,0deg',
        8: '64%,53%,0deg',
        9: '64%,57%,0deg',
        0: '64%,62%,0deg',
        '#': '87%,44%,0deg',
        '\\': '9%,15%,0deg',
        '/': '12%,78%,0deg'
      }
    }
  },

  methods: {
    makeMove (e) {
      if (this.keysMap[e.key]) {
        console.log(this.keysMap[e.key])
        const coordinates = this.keysMap[e.key].split(',')
        this.$refs.planchette.style.top = coordinates[0]
        this.$refs.planchette.style.left = coordinates[1]
        this.$refs.planchette.style.transform = `rotate(${coordinates[2]})`
      }
      console.log(e)
    }
  },

  mounted () {
    window.addEventListener('keypress', this.makeMove)
  },

  beforeDestroy () {
    window.removeEventListener('keypress', this.makeMove)
  }
}
</script>

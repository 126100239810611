<template>
  <div class="mastermind">
    <user-name-box :channel="currentChannel"></user-name-box>
    <div v-if="!isSettingsDone">
      <div class="game-body">
        <div class="cover-page">
          <img src="/images/mastermind/mastermind-cover.jpg" alt="Cover page">
        </div>
        <div class="game-setting-wrapper pos-rel">
          <div class="game-name">Master Mind</div>
          <p>
            Master Mind is a code-breaking game for two players.
          </p>
          <div class="game-setting">
            <div @click="setEasySettings" :class="{'active' : isEasySetting && isSinglePlayer}">Easy</div>
            <div @click="setHardSettings" :class="{'active' : isHardSetting && isSinglePlayer}">Hard</div>
            <!-- <div @click="setMultiPlayer" :class="{'active' : !isSinglePlayer}">Multi-player</div> -->
          </div>
          <div>
            <button class="btn btn-primary btn-lg btn-block" @click="kickOffGame">Let's play</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSettingsDone && !isGameReady && isInitiator && !isSinglePlayer" class="channel-link-wrapper">
      <div>
        <div  class="channel-link pos-rel" v-if="isInitiator && !isSinglePlayer">
          <div class="m-b-sm">
            ⭐Hey {{ userName}}, You are the <strong>Code maker</strong>.
          </div>
          <div class="pos-rel m-b-sm">
            Pass this link to other players before clicking Ready:
            <span @click="copyToClipboard(gameLink)" class="clickable">
              <strong>{{ gameLink }}</strong>
            </span>
            <transition name="fade">
              <div v-if="isCopiedToClipboard" class="copy-msg">Copied to clipboard</div>
            </transition>
          </div>
        </div>
        <transition name="fade">
          <div v-if="isInitiator" class="pos-rel">
            <button class="btn btn-primary btn-lg btn-block" @click="readyTheGame">Ready</button>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="isGameReady" class="mastermind-wrapper">
      <div class="mastermind-panel">
        <div
          v-for="(chance, rowIndex) in chances"
          :key="rowIndex"
          class="mastermind-chance"
          :class="{'highlight' : rowIndex === currentWorkingRow}">
          <transition name="fade">
            <div class="mastermind-chance-correct" v-if="chance.totalCorrect">
              {{ chance.totalCorrect }}
              <span class="show-on-hover">Correct color at correct place</span>
            </div>
          </transition>
          <div class="mastermind-chance-color-wrapper">
            <div
              v-for="(color, colIndex) in chance.colors"
              :key="`${rowIndex}-${colIndex}`"
              class="mastermind-chance-color"
              :class="{'highlight' : currentWorkingCol === colIndex && currentWorkingRow === rowIndex}"
              :style="{background: color.name}"
              @click="changeColorColumn(colIndex)">
            </div>
          </div>
          <transition name="fade">
            <div class="mastermind-chance-incorrect" v-if="chance.totalIncorrect">
              {{ chance.totalIncorrect }}
              <span class="show-on-hover">Correct color at wrong place</span>
            </div>
          </transition>
          <transition name="slide-fade-y">
            <div
              v-if="currentWorkingRow === rowIndex"
              class="mastermind-chance-color-choice">
              <div v-for="(color, index) in currentColorSet" :key="index"
                class="mastermind-chance-color"
                :style="{background: color}"
                @click="updateColorOnSelectedCircle($event, color)"></div>
              <div class="mastermind-chance-color-btn" v-if="currentWorkingRow === rowIndex">
                <div v-if="!hasLostTheGame && !hasWonTheGame">
                  <button @click="checkCurrentChance" class="btn btn-primary btn-lg btn-block" :disabled="!isCurerntChanceReadyForCheck">Check</button>
                </div>
                <div v-if="hasLostTheGame || hasWonTheGame">
                  <button @click="readyTheGame" class="btn btn-primary btn-lg btn-block">Start again</button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <modal v-model="isShowModal" v-if="hasWonTheGame">
      <div slot="main">
        <div class="m-b-sm">Congratulations! You have won the game.</div>
        <div><button @click="readyTheGame" class="btn btn-primary btn-lg btn-block">Start again</button></div>
      </div>
    </modal>
    <modal v-model="isShowModal" v-if="hasLostTheGame">
      <div slot="main">
        <div class="m-b-sm">Sorry! You have lost the game.</div>
        <div class="m-b-sm"><label>Code was:</label></div>
        <div class="m-b-md mastermind-chance-color-wrapper">
          <div v-for="(color, index) in correctCode" :key="index"
            class="mastermind-chance-color"
            :style="{background: color}"></div>
        </div>
        <div><button @click="readyTheGame" class="btn btn-primary btn-lg btn-block">Start again</button></div>
      </div>
    </modal>
  </div>
</template>

<script>
import { CodeMaker } from '@/services/mastermind/CodeMaker'
import { mapState } from 'vuex'
import Gamemixin from '@/components/assist/game_mixin'

const codeMaker = new CodeMaker()
export default {
  name: 'mastermind',

  mixins: [Gamemixin],

  data () {
    return {
      availableColors: ['red', 'blue', 'pink', 'yellow', 'purple', 'green', 'cyan', 'orange'],
      totalChances: 8,
      totalColors: 8,
      codeLength: 4,
      chances: [],
      currentWorkingRow: 0,
      currentWorkingCol: 0,
      currentColorSet: [],
      hasWonTheGame: false,
      hasLostTheGame: false,
      correctCode: [],
      isShowModal: false,
      isBlanksAllowed: false,
      isDuplicateAllowed: false,
      settingType: 'easy',
      selectedUser: null,
      isRequestSent: false,
      game: 'mastermind'
    }
  },

  computed: {
    ...mapState({
      userName: state => state.user.name,
      onlineUsers: state => state.user.users
    }),

    currentChance () {
      return this.chances[this.currentWorkingRow]
    },

    currentColor () {
      return this.currentChance && this.currentChance.colors[this.currentWorkingCol]
    },

    isCurerntChanceReadyForCheck () {
      return this.currentChance && !this.currentChance.isChecked && this.currentChance.colors.every(color => color.name !== null)
    },

    isEasySetting () {
      return this.settingType === 'easy'
    },

    isHardSetting () {
      return this.settingType === 'hard'
    },

    isCustomSetting () {
      return this.settingType === 'custom'
    },

    isMultiPlayerSetting () {
      return this.settingType === 'multi-player'
    }
  },

  methods: {
    setChances () {
      this.chances = []
      this.generateArray(this.totalChances).forEach(() => {
        this.chances.push({
          name: '',
          colors: this.generateArray(this.codeLength).map(() => {
            return {
              name: null
            }
          }),
          totalCorrect: 0,
          totalIncorrect: 0,
          isChecked: false
        })
      })
    },

    changeColorColumn (colIndex) {
      this.currentWorkingCol = colIndex
    },

    readyTheGame () {
      this.currentColorSet = this.availableColors.slice(0, this.totalColors)
      codeMaker.setColors(this.currentColorSet)
      codeMaker.setCodeLen(this.codeLength)
      codeMaker.setDuplicates(this.isDuplicateAllowed)
      codeMaker.generateCode()
      this.setChances()
      this.currentWorkingRow = 0
      this.currentWorkingCol = 0
      this.isGameReady = true
      this.hasLostTheGame = false
      this.hasWonTheGame = false
    },

    requestGame () {
      this.isRequestSent = true
      this.$socket.emit('game-request', {
        id: this.selectedUser.id,
        from: this.userName,
        game: 'mastermind'
      })
    },

    updateColorOnSelectedCircle ($event, color) {
      $event.stopPropagation()
      this.currentChance.colors[this.currentWorkingCol].name = color
      const unfilledIndex = this.currentChance.colors.findIndex(color => !color.name)
      if (unfilledIndex > -1) {
        this.changeColorColumn(unfilledIndex)
      } else {
        this.changeColorColumn(0)
      }
    },

    checkCurrentChance () {
      const output = codeMaker.checkCode(this.currentChance.colors.map(color => color.name))
      this.currentChance.totalCorrect = output.CorrectPlaces
      this.currentChance.totalIncorrect = output.IncorrectPlaces
      this.currentChance.isChecked = true
      if (this.currentChance.totalCorrect === this.codeLength) {
        this.hasWonTheGame = true
        setTimeout(() => {
          this.isShowModal = true
        }, 100)
      } else if (this.currentWorkingRow < this.totalChances - 1) {
        this.currentWorkingRow++
      } else if (this.currentWorkingRow === this.totalChances - 1) {
        this.hasLostTheGame = true
        this.correctCode = codeMaker.getCode()
        setTimeout(() => {
          this.isShowModal = true
        }, 100)
      }
    },

    setEasySettings () {
      this.isBlanksAllowed = false
      this.isDuplicateAllowed = false
      this.totalColors = 6
      this.totalChances = 8
      this.codeLength = 4
      this.settingType = 'easy'
    },

    setHardSettings () {
      this.isBlanksAllowed = true
      this.isDuplicateAllowed = true
      this.totalColors = 8
      this.totalChances = 8
      this.codeLength = 4
      this.settingType = 'hard'
    },

    setMultiPlayer () {
      this.isSinglePlayer = false
    },

    setCustomSettings () {
      this.isBlanksAllowed = false
      this.isDuplicateAllowed = false
      this.totalColors = 8
      this.totalChances = 8
      this.codeLength = 4
      this.settingType = 'custom'
    },

    setMultiplayerSettings () {
      this.settingType = 'multi-player'
    }
  },

  mounted () {
    this.setEasySettings()
  }
}
</script>

<template>
  <component v-if="component" :is="component" :height="height">
  </component>
</template>

<script>
export default {
  name: 'CardFactory',

  props: {
    suit: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    height: {
      type: String,
      default: '150px'
    }
  },

  data () {
    return {
      component: null
    }
  },

  computed: {
    loader () {
      return () => import(`./${this.suit}/${this.label}`)
    }
  },

  mounted () {
    this.loader().then(() => {
      this.component = () => this.loader()
    })
  }
}
</script>

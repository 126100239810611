import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/components/Home.vue'
import MasterMind from '@/components/mastermind/Game.vue'
import Ouija from '@/components/ouija/Game.vue'
import Grab from '@/components/grab/Game.vue'
import Liar from '@/components/liar/Game.vue'
import Cards from '@/components/cards/Game.vue'
import TruthDare from '@/components/truthdare/Game.vue'
import RockPaperScissors from '@/components/rockpaperscissors/Game.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
      path: 'mastermind',
      name: 'Mastermind',
      component: MasterMind
    }, {
      path: 'truthdare',
      name: 'truthdare',
      component: TruthDare
    }, {
      path: 'grab',
      name: 'grab',
      component: Grab,
      children: [{
        path: 'game',
        name: 'grabgame',
        component: Grab
      }]
    }, {
      path: 'ouija',
      name: 'ouija',
      component: Ouija,
      children: []
    }, {
      path: 'liar',
      name: 'liar',
      component: Liar,
      children: []
    }, {
      path: 'cards',
      name: 'cards',
      component: Cards,
      children: []
    }, {
      path: 'rockpaperscissors',
      name: 'rockpaperscissors',
      component: RockPaperScissors,
      children: []
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="grab">
    <user-name-box :channel="currentChannel"></user-name-box>

    <!-- Setting page -->
    <transition name="fade" v-if="!loading">
      <div class="game-body" v-if="!isSettingsDone" >
        <div class="m-b-md cover-page">
          <img src="/images/rockpaperscissors/cover.svg" alt="Cover page" width="200">
        </div>
        <div class="game-setting-wrapper pos-rel">
          <div class="game-name">Rock Paper Scissors</div>
          <p>
            Rock smashes Scissors. Scissors cut Paper. Paper wraps Rock. Maximum two players can play this game.
          </p>
          <button class="btn btn-primary btn-lg btn-block" @click="kickOffGame">
            Get the link to start playing
          </button>
        </div>
      </div>
    </transition>

    <!-- Actual game play -->
    <transition name="fade" v-if="!loading">
      <div class="game-body">
        <!-- Cover page -->
        <div class="m-b-md cover-page">
          <img  v-if="!isGameReady" src="/images/rockpaperscissors/cover.svg" alt="Cover page" width="200">
        </div>
        <!-- Channel link and Ready button -->
        <div v-if="!isGameReady && isInitiator">
          <channel-link-message :game-link="gameLink">
            <div slot="greeting"></div>
            <div  v-if="gameUsers.length > 1" slot="message">
              {{ gameUsers.length }} players have joined the link.
            </div>
          </channel-link-message>
          <div class="pos-rel">
            <button :disabled="(minAllowedPlayers > gameUsers.length)" class="btn btn-primary btn-lg btn-block" @click="readyTheGame">Ready</button>
          </div>
        </div>
        <div v-if="!isGameReady && !isInitiator" class="game-setting-wrapper">
          <div class="game-name">Rock Paper Scissor</div>
          <p>
            Game is about to start!
          </p>
        </div>
        <!-- Next progress button -->
        <div v-if="isGameReady && !isCurrentGameDone" class="game-setting-wrapper pos-rel">
          <span v-for="obj in objects" :key="obj" @click="onItemSelection(obj)" :class="{'clickable' : !currentSelection}">
            <component :is="obj" :fill="currentSelection === obj ? 'blue' : '#fff'"></component>
          </span>
          <p v-if="currentSelection">Waiting for other player to make a selection</p>
        </div>

        <div v-if="isCurrentGameDone" class="game-setting-wrapper pos-rel">
          <div v-for="user in userSelections" :key="user.socketId" class="inl-blk">
            <component :is="user.selection" :fill="user.isWinner ? '#f7e18e' : '#404040'"></component>
            <p>{{ user.userName }}</p>
          </div>
          <div v-if="isInitiator">
            <button @click="playAgain" class="btn btn-primary">Play again</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GameMixin from '../assist/game_mixin.js'
import Paper from './objects/Paper'
import Rock from './objects/Rock'
import Scissors from './objects/Scissors'

export default {
  name: 'rockpaperscissors',

  mixins: [
    GameMixin
  ],

  components: {
    Paper,
    Rock,
    Scissors
  },

  data () {
    return {
      game: 'rockpaperscissors',
      minAllowedPlayers: 2,
      maxAllowedPlayers: 2,
      currentChannel: null,
      objects: ['rock', 'paper', 'scissors'],
      userSelections: [],
      currentSelection: null,
      isSinglePlayer: false
    }
  },

  computed: {
    isCurrentGameDone () {
      return this.userSelections.length === this.gameUsers.length
    }
  },

  methods: {
    onItemSelection (item) {
      if (this.currentSelection) {
        return
      }
      this.currentSelection = item
      this.$socket.emit('game-item-selected', {
        name: this.userName,
        selection: item,
        channel: this.currentChannel,
        socketId: this.$socket.id
      })
    },

    setChannelEvents () {
      if (!this.currentChannel) {
        return
      }
      this.receiveGameReadyEvent()
      this.$socket.on(`game-item-selected-${this.currentChannel}`, (data) => {
        if (data.selection === null) {
          this.userSelections = []
          this.currentSelection = null
          return
        }
        this.userSelections.push({
          userName: data.name,
          socketId: data.socketId,
          selection: data.selection,
          isWinner: false
        })
        if (this.userSelections.length === 2) {
          const winnerSelection = this.whoIsWinner(this.userSelections.map(s => s.selection))
          this.userSelections.filter(s => s.selection === winnerSelection).forEach(s => {
            s.isWinner = true
          })
        }
      })
    },
    whoIsWinner (selections) {
      if (selections.length !== 2 || selections[0] === selections[1]) {
        return null
      }
      const combinations = [{
        selections: ['rock', 'paper'],
        winner: 'paper'
      }, {
        selections: ['rock', 'scissors'],
        winner: 'rock'
      }, {
        selections: ['scissors', 'paper'],
        winner: 'scissors'
      }]
      const combination = combinations.find(c => c.selections.every(selection => selections.includes(selection)))
      return combination ? combination.winner : null
    },

    playAgain () {
      this.$socket.emit('game-item-selected', {
        name: this.userName,
        selection: null,
        channel: this.currentChannel,
        socketId: this.$socket.id
      })
    }
  }
}
</script>

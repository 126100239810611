<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="100px" width="100px" :fill="fill" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><path d="M47.6,31.8l-27.9-6.4c-1.5-0.4-3.1-0.2-4.4,0.6c-1.4,0.8-2.4,2.1-2.8,3.6c-0.8,3.2,1,6.4,4.1,7.3l24.2,6.8l-27,5  c-1.7,0.3-3.2,1.2-4.2,2.7c-1,1.4-1.4,3.1-1.1,4.8c0.3,1.7,1.2,3.2,2.7,4.2c1.4,1,3.1,1.4,4.8,1.1l21.5-3.8c0,0.3,0,0.5,0,0.8  c0,3,2.1,5.6,4.9,6.2c-0.7,1-1.1,2.3-1.1,3.6c0,3.6,2.9,6.4,6.4,6.4l16.6,0c13,0,18.6-2.2,22.7-3.8c2.3-0.9,4-1.6,6.4-1.6  c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-3,0-5.1,0.8-7.5,1.8c-3.4,1.3-7.9,3.1-17.2,3.5c0.6-1,0.9-2.1,0.9-3.3  c0-3-2.1-5.6-4.9-6.2c0.7-1,1.1-2.3,1.1-3.6c0-3.6-2.9-6.4-6.4-6.4h-8.5c2.6-1.8,4-4.9,5.2-7.7l6.9-2.2c1.5,7.5,11.3,10.6,11.7,10.7  c0.8,0.2,1.6-0.2,1.9-1c0.2-0.8-0.2-1.6-1-1.9c-0.1,0-9.8-3-9.8-9.8c0-0.5-0.2-0.9-0.6-1.2s-0.9-0.4-1.3-0.2l-9.3,3  c-0.4,0.1-0.8,0.4-0.9,0.8C51,48.7,49,51.6,43.5,50l3.8-9.6c0.6-1.4,1.4-2.5,2.8-3.4l9.6-4.4c1.7-0.5,2.4-0.5,4.4-0.5l0.2,0  c10.7,0.3,16.3,2.8,20.8,4.8c2.9,1.3,5.4,2.4,8.4,2.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-2.4,0-4.4-0.9-7.2-2.1  c-4.5-2-10.6-4.7-21.9-5.1h-0.3c-2.2,0-3.2,0-5.4,0.7c-0.1,0-0.1,0-0.2,0.1L53,32.4c0,0-0.1,0-0.1,0L47.6,31.8z M63.2,64.9  c1.9,0,3.4,1.5,3.4,3.4c0,1.8-1.4,3.3-3.1,3.4l-15.7,0c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4H63.2z M59.4,55  c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4H47.8H44c-1.9,0-3.4-1.5-3.4-3.4S42.1,55,44,55H59.4z M40.2,50.3c-0.1,0.4-0.1,0.8,0,1.2  c0.2,0.4,0.5,0.7,0.8,0.8c0.2,0.1,0.4,0.1,0.5,0.2c-1,0.4-1.8,1.1-2.5,1.9l-23.4,4.1c-0.9,0.2-1.8,0-2.6-0.6  c-0.8-0.5-1.3-1.3-1.4-2.2c-0.2-0.9,0-1.8,0.6-2.6c0.5-0.8,1.3-1.3,2.2-1.4l27.2-5L40.2,50.3z M44.5,39.3l-0.8,2.1l-26.2-7.4  c-1.5-0.4-2.5-2.1-2-3.6c0.2-0.8,0.7-1.4,1.4-1.8c0.7-0.4,1.4-0.5,2.2-0.3l28,6.4c0.1,0,0.1,0,0.2,0l0.8,0.1  C46.4,36.1,45.3,37.5,44.5,39.3z"/></svg>
</template>

<script>
export default {
  name: 'Scissors',

  props: {
    fill: {
      type: String,
      required: false,
      default: '#fff'
    }
  }
}
</script>

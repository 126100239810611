<template>
<transition name="fade">
  <div v-if="value">
    <div class="v-modal-wrapper" ref="modalWrapper">
      <div class="v-modal" :class="{[modalCss]: modalCss}" ref="modal">
        <header class="v-modal-header" v-if="$slots.header" :class="headerCss">
          <span v-if="showClose" @click="closeModal" class="btn-close float-right m-l-md">&times;</span>
          <slot name="header"></slot>
        </header>
        <div class="v-modal-sub-header" v-if="$slots.subHeader" :class="headerCss">
          <slot name="subHeader"></slot>
        </div>
        <main class="v-modal-body" v-if="$slots.main" :style="{'height': bodyHeight}">
          <span v-if="!$slots.header && showClose" @click="closeModal" class="btn-close float-right m-l-md">&times;</span>
          <slot name="main"></slot>
        </main>
        <footer class="v-modal-footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
export default {
  props: {
    modalCss: String,
    keyboard: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      required: false
    },
    bodyHeight: {
      type: String,
      required: false
    },
    headerCss: {
      type: String,
      required: false
    },
    value: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isDragOn: false
    }
  },

  watch: {
    value () {
      if (this.value) {
        this.setupEvents()
      } else {
        this.removeEvents()
      }
    }
  },

  methods: {
    closeOnPressingEscapeKey (e) {
      if (e.keyCode === 27) {
        this.closeModal()
      }
    },

    setDraggingOn () {
      this.isDragOn = true
    },

    setDraggingOff () {
      this.isDragOn = false
    },

    closeModal () {
      this.$emit('input', false)
      this.$emit('close')
    },

    setupEvents () {
      if (this.keyboard) {
        window.addEventListener('keyup', this.closeOnPressingEscapeKey)
      }
      if (this.backdrop !== 'static') {
        document.addEventListener('mousedown', this.setDraggingOff)
        document.addEventListener('mousemove', this.setDraggingOn)
        if (this.$refs.modalWrapper) {
          this.$refs.modalWrapper.addEventListener('click', (event) => {
            if (!this.$refs.modal.contains(event.target) && !this.isDragOn) {
              this.closeModal()
            }
          })
        }
      }
      if (this.width) {
        this.$refs.modal.style.width = this.width
      }
    },

    removeEvents () {
      window.removeEventListener('keyup', this.closeOnPressingEscapeKey)
      document.removeEventListener('mousedown', this.setDraggingOff)
      document.removeEventListener('mousemove', this.setDraggingOn)
    }
  },

  destroyed () {
    this.removeEvents()
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/mixin.scss';
  .btn-close {
    cursor: pointer;
    color: rgba(169, 166, 166, 0.5);
    font-size: 21px;
    line-height: 20px;
  }

  .v-modal-wrapper {
    @include flex();
    align-items: center;
    @include justify-content(center);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .v-modal {
    background-color: #1a1a1a;
    color: white;
    border-radius: 3px;

    @include desktop {
      &.v-modal-xs {
        width: 30vw;
      }
      &.v-modal-sm {
        width: 35vw;
      }
      &.v-modal-md {
        width: 50vw;
      }
      &.v-modal-lg {
        width: 70vw;
      }
      &.v-modal-xl {
        width: 95vw;
      }
    }
  }

  .v-modal-header {
    padding: 15px 20px;
    // background-color: $darkBgr;
    // border-bottom: 1px solid $darkBgr;
    color: white;
    font-size: 1.2em;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .v-modal-body {
    position: relative;
    padding: 20px;
    max-height: 80vh;
    transition: height 0.3s linear;
    &.with-overflow {
      overflow-y: scroll;
    }
  }

  .v-modal-footer {
    padding: 10px 20px;
    border-top: 1px solid #f1f1f1;
    background: #f9f9f9;
    text-align: right;
  }

  .modal-sub-header {
    padding: 10px 20px;
    border-bottom: 1px solid #f1f1f1;
    background: #f9f9f9;
  }
  .fade-enter-active, .fade-leave-active {
    .v-modal {
      transition: all .5s;
    }
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    .v-modal {
      transform: translateY(-100px);
      opacity: 0;
    }
  }
</style>

<template>
  <div class="cards">
    <user-name-box :channel="currentChannel"></user-name-box>

    <!-- Introduction -->
    <transition name="fade" v-if="!loading && !currentChannel">
      <div class="game-body">
        <div class="m-b-md cover-page">
          <img :src="`/images/cards/cover-page.svg`" alt="Cover page">
        </div>
        <div class="game-setting-wrapper pos-rel">
          <div class="game-name">Cards</div>
          <p>
            Miss the childhood card games that you used to play with your friends. We let you play your own card game with your own set of rules
            which just involves pack or packs of cards.
          </p>
          <button class="btn btn-primary btn-lg btn-block" @click="kickOffGame">
            Let's play
          </button>
        </div>
      </div>
    </transition>

    <!-- Set the game rules -->
    <transition name="fade" v-if="!loading && currentChannel && !isGameReady">
      <div class="game-body">
        <div class="m-b-md cover-page">
          <img :src="`/images/cards/cover-page.svg`" alt="Cover page">
        </div>
        <div v-if="isInitiator">
          <channel-link-message
            v-if="!isSinglePlayer"
            :game-link="gameLink">
            <div slot="greeting" class="m-b-sm">
              ⭐Hey {{ userName }}, You are the <strong>Dealer</strong>. Set your game rules here:
            </div>
            <div slot="action">
              <div class="m-b-sm">
                <label class="m-r-md">Pack of cards:</label>
                <input type="text" v-model="numberOfPacks">
              </div>
              <div class="m-b-sm">
                <label class="m-r-md">Cards to divide:</label>
                <input type="text" v-model="cardsToDivide">
              </div>
              <div class="m-b-sm">
                <label class="m-r-md">Minimum players:</label>
                <input type="number" v-model="minPlayers">
              </div>
              <div class="m-b-sm">
                <label class="m-r-md">Maximum players:</label>
                <input type="number" v-model="maxPlayers">
              </div>
              <div v-if="gameUsers.length < minPlayers">Game will not start until {{ minPlayers }} players have joined. So far {{ gameUsers.length}} have joined.</div>
            </div>
          </channel-link-message>
          <div class="pos-rel">
            <button
              class="btn btn-primary btn-lg btn-block"
              @click="readyTheGame"
              :disabled="gameUsers.length < minPlayers">Ready</button>
          </div>
        </div>
        <div v-if="!isInitiator" class="game-setting-wrapper">
          <div class="game-name">Cards</div>
          <p>
            Game is about to start.
          </p>
        </div>
      </div>
    </transition>

    <!-- Game ready -->
    <transition name="fade" v-if="!loading && currentChannel && isGameReady">
      <div class="game-body">
        <div class="m-b-md cover-page"></div>
        <!-- players' round table -->
        <div class="players-table">
          <div
            v-for="(player, index) in gameUsers"
            :key="player.name"
            :style="playerPositionStyle(index, gameUsers.length)">
            <player
              :rotation="`${getPlayerAngle(index, gameUsers.length)}deg`"
              :width="'150px'"
              :height="'150px'"
              :name="player.socketId && player.socketId === $socket.id ? 'You' : player.name"
              :fill="player.socketId === initiatorId ? '#9bcb00' : '#3e464f'">
            </player>
          </div>
          <div v-if="!isDealingDone && initiatorId === $socket.id" :style="centerPosition">
            <button class="btn btn-primary" @click="dealCards">Deal</button>
          </div>
          <!-- Discard pile in between the table -->
          <draggable
            v-if="isDealingDone"
            v-model="discardPileCards"
            :group="{name: 'discarded', pull: ['playing'], put: ['playing']}"
            :sort="false"
            tag="div"
            class="discarded-cards"
            :style="centerPosition"
            @add="onDroppingCardToDiscardedPile"
            @change="onDiscardedPileChange">
            <span
              class="card-wrapper"
              v-for="(card) in discardPileCards"
              :key="card.suit + card.identifier">
              <card
                :suit="card.suit"
                :label="card.identifier"></card>
            </span>
          </draggable>
        </div>
        <!-- Player's cards -->
        <drag-resize
          v-if="myPlayingCards.length"
          :is-resizable="false"
          :is-active="true"
          :w="cardsPos.w"
          :h="cardsPos.h"
          :x="cardsPos.x"
          :y="cardsPos.y"
          drag-handle=".playing-cards-move"
          class="players-cards-box">
          <span class="playing-cards-move">Move me</span>
          <div ref="playingCards">
            <draggable
              tag="div"
              class="players-cards"
              v-model="myPlayingCards"
              :group="{name: 'playing', pull: ['discarded'], put: ['discarded']}">
              <span
                class="card-wrapper"
                v-for="(card) in myPlayingCards"
                :key="card.suit + card.identifier">
                <card
                  :suit="card.suit"
                  :label="card.identifier"></card>
              </span>
            </draggable>
          </div>
        </drag-resize>
      </div>
    </transition>
    <!-- <div v-else-if="!isGameReady && !loading && currentChannel" class="game-body">
      <div class="m-b-md cover-page"></div>
      Game is not started yet. Please wait!
    </div> -->
  </div>
</template>

<script>
import GameMixin from '@/components/assist/game_mixin.js'
import CircleSittingMixin from '@/components/assist/circle_sitting_mixin.js'
import { CardsPack } from '@/services/cards/CardsPack'
import Player from '@/components/shared/Player'
import Card from '@/components/shared/cards/Card'
import Draggable from 'vuedraggable'
import DragResize from 'vue-drag-resize'

export default {
  name: 'cards',

  components: {
    Player,
    Card,
    Draggable,
    DragResize
  },

  mixins: [
    GameMixin,
    CircleSittingMixin
  ],

  data () {
    return {
      isShowModal: false,
      game: 'cards',
      selectedItem: null,
      isCurrentGameDone: false,
      $pack: null,
      myPlayingCards: [],
      numberOfPacks: 1,
      maxPlayers: 10,
      minPlayers: 2,
      cardsToDivide: 10,
      allowDealershipTransfer: false,
      unplayedCards: [],
      initiatorId: null,
      whoseTurnIsIt: null,
      allowToPickFromDiscardPile: null,
      discardPileCards: [],
      isDealingDone: false,
      cardsPos: {
        x: 100,
        y: 100,
        w: 80,
        h: 30
      }
    }
  },

  computed: {
    discardedReversePile () {
      return this.discardPileCards.slice().reverse()
    }
  },

  methods: {
    setChannelEvents () {
      if (!this.currentChannel) {
        return
      }
      this.receiveGameReadyEvent()
      this.receiveMetaDataForCurrentUser()
    },

    readyTheGame () {
      this.$pack.setTotalPacks(this.numberOfPacks)
      this.$pack.setCards()
      this.$pack.shuffleCards()
      this.isGameReady = true
      this.initiatorId = this.$socket.id
      this.sendGameReadyEvent()
      this.unplayedCards = this.$pack.getAllCards()
    },

    dealCards () {
      this.isDealingDone = true
      this.gameUsers.forEach(user => {
        this.sendMetaDataToUser('initiatorId', this.$socket.id, user)
        this.sendMetaDataToUser('cards', this.$pack.fetchCards(this.cardsToDivide), user)
        this.sendMetaDataToUser('isDealingDone', true, user)
      })
    },

    sendMetaDataToUser (prop, value, user) {
      this.$socket.emit('add-user-meta-to-channel', {
        socketId: user.socketId,
        channel: this.currentChannel,
        meta: {
          [prop]: value,
          initiatorId: this.$socket.id
        }
      })
    },

    receiveMetaDataForCurrentUser () {
      this.$socket.on(`user-${this.currentChannel}-${this.$socket.id}`, (meta) => {
        if (meta.cards) {
          this.myPlayingCards = meta.cards
        }
        if (meta.discardedCards) {
          this.discardPileCards = meta.discardedCards
        }
        if (meta.initiatorId) {
          this.initiatorId = meta.initiatorId
          this.isGameReady = true
        }
        if (meta.isDealingDone) {
          this.isDealingDone = true
        }
      })
    },

    shouldUserBeAllowedOnChannel () {
      if (this.isInitiator) {
        return true
      }
      if (this.isGameReady) {
        return false
      }
      return this.gameUsers.length > 0 &&
        this.gameUsers[0].meta &&
        this.gameUsers.length < this.gameUsers[0].meta.maxPlayers
    },

    getUserMetaData () {
      return {
        maxPlayers: this.maxPlayers
      }
    },

    onDroppingCardToDiscardedPile (card) {
      console.log(card)
      console.log(this.discardPileCards)
    },

    onDiscardedPileChange () {
      console.log('Pile changed')
      this.gameUsers.filter(user => user.socketId !== this.$socket.id).forEach(user => {
        this.sendMetaDataToUser('discardedCards', this.discardPileCards, user)
      })
    },

    log (e) {
      console.log(e)
    }
  },

  mounted () {
    this.isSinglePlayer = false
    this.$pack = new CardsPack()
  }
}
</script>

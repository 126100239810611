import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Modal from '@/components/shared/Modal.vue'
import io from 'socket.io-client'

Vue.config.productionTip = false
console.log(process.env)
// process.env.BASE_URL || 'localhost:3001'
Vue.prototype.$socket = io()

new Vue({
  router,
  store,
  render: h => h(App),
  beforeMount () {
    Vue.component('modal', Modal)
  }
}).$mount('#app')

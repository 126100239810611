<template>
  <div class="grab">
    <user-name-box :channel="currentChannel"></user-name-box>

    <!-- Setting page -->
    <transition name="fade" v-if="!loading">
      <div class="game-body" v-if="!isSettingsDone" >
        <div class="m-b-md cover-page" v-if="theme">
          <img :src="`/images/grab/${theme.code}/cover.jpg`" alt="Cover page" width="200">
        </div>
        <div class="game-setting-wrapper pos-rel">
          <div class="game-name">Grab</div>
          <p>
            Grab is a party / team / kids friendly game that is all about quick reactions and quicker hands.
            It is inspired from the geistsblitz game to be able to play online with friends and family or to pass the time alone.
          </p>
          <p>Choose one of the themes below to start playing:</p>
          <div class="m-b-md game-setting">
            <div v-for="t in themes" :key="t.name" @click="theme=t" :class="{'active': theme && theme.name === t.name}">{{ t.name }}</div>
          </div>
          <div class="game-setting-players">
            <label><input type="radio" v-model="isSinglePlayer" :value="true" name="playerMode">Single player</label>
            <label><input type="radio" v-model="isSinglePlayer" :value="false"  name="playerMode">Multi player</label>
          </div>
          <button class="btn btn-primary btn-lg btn-block" @click="kickOffGame">
            Let's play
          </button>
        </div>
      </div>
    </transition>
    <!-- Actual game play -->
    <transition name="fade" v-if="!loading">
      <div class="game-body" v-if="isSettingsDone && theme">
        <!-- Cover page -->
        <div class="m-b-md cover-page" v-if="theme">
          <img :src="`/images/grab/${theme.code}/cover.jpg`" alt="Cover page" width="200">
        </div>
        <!-- Table with all the objects -->
        <div class="item-container pos-rel">
          <div class="item-wrapper">
            <div
              class="item"
              :class="getClassForItem(item)"
              v-for="item in theme.items"
              :key="item.name"
              @click="onItemSelection(item)">
              <theme-item
                :theme="theme.code"
                :width="item.width * screenScaleFactor"
                :item="item.code"
                :fill1="item.fill1"
                :fill2="item.fill2"
                :fill3="item.fill3"
                :stroke1="item.stroke1"
                :top="item.top * screenScaleFactor"
                :left="item.left * screenScaleFactor"></theme-item>
            </div>
          </div>

          <!-- Channel link and Ready button -->
          <div v-if="!isGameReady && isInitiator">
            <channel-link-message v-if="!isSinglePlayer" :game-link="gameLink">
              <div  v-if="gameUsers.length > 1" slot="message">
                {{ gameUsers.length }} players in the game so far
              </div>
            </channel-link-message>
            <transition name="fade">
              <div class="pos-rel">
                <button :disabled="isSinglePlayer ? false : (minAllowedPlayers > gameUsers.length)" class="btn btn-primary btn-lg btn-block" @click="readyTheGame">Ready</button>
              </div>
            </transition>
          </div>
          <div v-if="!isGameReady && !isInitiator">
            Game is about to start!
          </div>
          <!-- Next progress button -->
          <transition name="fade" v-if="isInitiator && isGameReady">
            <div v-if="!cardCombination && !hasShownCardOnce && totalFlashedCards < maxCards">
              <button class="btn btn-success btn-lg btn-block pos-rel" @click="showNextCard">Show first card</button>
            </div>
            <div v-else-if="totalFlashedCards < maxCards && isCurrentGameDone">
              <button class="btn btn-success btn-lg btn-block pos-rel" @click="showNextCard">Next</button>
            </div>
            <div v-else>
              <div class="not-mobile" :style="{height: '55px'}"></div>
              <div class="only-mobile" :style="{height: '34px'}"></div>
            </div>
          </transition>

          <!-- Game over popup -->
          <modal v-if="totalFlashedCards >= maxCards && isCurrentGameDone" @close="$router.push({name: 'Home'})">
            <div slot="main">
              <h2>Game over!</h2>
              <div class="m-b-sm" v-if="isSinglePlayer">Your score was {{ singlePlayerScore }} / {{ totalFlashedCards }}</div>
              <div class="m-b-sm" v-else>Hope you enjoyed the game!</div>
              <button class="btn btn-primary btn-lg btn-block pos-rel" @click="readyTheGame">Start again</button>
            </div>
          </modal>

          <!-- Single player score -->
          <transition name="fade">
            <div v-if="isSinglePlayer && isGameReady" class="score">Score: {{ singlePlayerScore }}</div>
          </transition>
        </div>

        <!-- Leader board / card combo / selections -->
        <div class="grab-flexbox pos-rel" v-if="isGameReady">
          <div class="selections" v-if="!isSinglePlayer">
            <div class="m-b-lg">
              <transition-group name="list" v-if="cardCombination">
                <div class="m-b-sm selection" v-for="(item, index) in receivedItems" :key="`${item.name}-${index}`" :class="{'winner' : item.isWinner}">
                  <span v-if="item.isWinner" class="m-r-sm">🏆</span>
                  <em><strong>{{ item.selection }}</strong> by {{ item.name }}</em>
                </div>
              </transition-group>
            </div>
          </div>
          <div class="card-stack expanded" v-if="isGameReady">
            <transition name="fade">
              <div class="card" v-if="cardCombination" :style="{background: chosenCardBg}" :class="{'faded' : isCurrentGameDone}">
                <theme-item
                  v-for="item in cardCombination"
                  :key="item.code"
                  :theme="theme.code"
                  :width="item.width"
                  :item="item.code"
                  :fill1="item.fill1"
                  :fill2="item.fill2"
                  :fill3="item.fill3"
                  :stroke1="item.stroke1"
                  :top="item.top"
                  :left="item.left"></theme-item>
              </div>
            </transition>
            <div v-if="isInitiator && isGameReady && cardCombination">
              <button class="btn btn-default" @click="readyTheGame">Start another game</button>
            </div>
          </div>
          <div class="leader-board" v-if="!isSinglePlayer">
            <h3>LEADERBOARD</h3>
            <div v-for="user in gameUsers" :key="user.socketId">{{ user.name }} <span>{{ user.totalWins }}</span></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GameMixin from '../assist/game_mixin.js'
import ThemeItem from './ThemeItem'

export default {
  name: 'grab',

  mixins: [
    GameMixin
  ],

  components: {
    ThemeItem
  },

  data () {
    return {
      isShowModal: false,
      game: 'grab',
      theme: null,
      chosenCardBg: null,
      singlePlayerScore: 0,
      maxCards: 60,
      totalFlashedCards: 0,
      minAllowedPlayers: 2,
      themes: [{
        name: 'Christmas',
        code: 'christmas',
        backgroundRadients: [
          'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(234,244,89,1) 0%, rgba(229,250,252,1) 98%, rgba(255,255,217,1) 100%)'
        ],
        items: [{
          code: 'present',
          name: 'Present',
          left: 0,
          top: 0,
          width: 148,
          fill1: '#f38e90',
          fill2: '#ca0a0f',
          stroke1: '#ca0a0f'
        }, {
          code: 'reindeer',
          name: 'Reindeer',
          fill1: '#A86324',
          fill2: '#663311',
          stroke1: '#663311',
          top: 17,
          left: 0,
          width: 136
        }, {
          code: 'star',
          name: 'Star',
          fill1: '#f9d71c',
          fill2: '#debf13',
          stroke1: '#000084',
          width: 164,
          left: -20,
          top: 1
        }, {
          code: 'snowman',
          name: 'Snowman',
          fill1: '#fff',
          fill2: '#eaeaea',
          stroke1: '#000',
          top: -34,
          left: -18,
          width: 140
        }, {
          code: 'tree',
          name: 'Christmas tree',
          fill1: '#8a0',
          fill2: '#450',
          stroke1: '#22280b',
          left: 0,
          top: 0,
          width: 166
        }]
      }, {
        name: 'Halloween',
        code: 'halloween',
        backgroundRadients: [
          'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(234,244,89,1) 0%, rgba(229,250,252,1) 98%, rgba(255,255,217,1) 100%)'
        ],
        items: [{
          name: 'Cat',
          code: 'cat',
          fill1: '#000',
          stroke1: '#000',
          width: 83,
          left: 0,
          top: 0
        }, {
          name: 'Ghost',
          code: 'ghost',
          fill1: '#fff',
          fill2: '#f2f2f2',
          stroke1: '#000',
          width: 138,
          top: -20,
          left: -27
        }, {
          name: 'Owl',
          code: 'owl',
          fill1: '#996c38',
          fill2: '#6c4c28',
          stroke1: '#5a4124',
          width: 85,
          left: 0,
          top: 0
        }, {
          name: 'Witch hat',
          code: 'witch_hat',
          fill1: '#800080',
          fill2: '#650365',
          stroke1: '#650365',
          width: 250,
          left: -47,
          top: -27
        }, {
          name: 'Pumpkin',
          code: 'pumpkin',
          fill1: '#f90',
          fill2: '#d45500',
          stroke1: '#a40',
          width: 152,
          left: 0,
          top: 0
        }]
      }],
      selectedItem: null,
      receivedItems: [],
      currentChannel: null,
      isCurrentGameDone: false,
      cardCombination: null,
      winningObject: null,
      hasShownCardOnce: false
    }
  },

  computed: {
    gameLink () {
      if (!this.currentChannel) {
        return
      }
      return `${window.location.origin}/${this.game}/game?channel=${this.currentChannel}&theme=${this.theme.code}`
    }
  },

  methods: {
    onItemSelection (item) {
      if (!this.cardCombination || this.isCurrentGameDone || !this.winningObject) {
        this.selectedItem = null
        return
      }
      const isWinner = this.winningObject.code === item.code
      this.selectedItem = item
      if (this.isSinglePlayer) {
        this.isCurrentGameDone = true
        if (isWinner) {
          this.singlePlayerScore++
          this.setFlashMessage(true)
        } else {
          this.setFlashMessage(false)
        }
        return
      }
      this.$socket.emit('game-item-selected', {
        name: this.userName,
        selection: item.name,
        channel: this.currentChannel,
        isWinner: isWinner,
        socketId: this.$socket.id
      })
    },

    changeGameRoute () {
      this.$router.replace({
        name: this.$route.name,
        query: {
          channel: this.currentChannel,
          theme: this.theme.code
        }
      })
    },

    setChannelEvents () {
      if (!this.currentChannel) {
        return
      }
      this.$socket.on(`game-item-selected-${this.currentChannel}`, (data) => {
        if (!this.isCurrentGameDone && data.isWinner) {
          if (this.isInitiator) {
            this.$socket.emit('add-winner-to-channel', {
              socketId: data.socketId,
              channel: this.currentChannel
            })
          }
          this.isCurrentGameDone = true
        } else {
          data.isWinner = false
        }
        // Let host machine decide who is the winner
        this.receivedItems.push(data)
      })

      this.$socket.on(`game-card-combo-${this.currentChannel}`, (data) => {
        this.cardCombination = data.cardCombo
        this.winningObject = data.winnerCard
        this.chosenCardBg = data.chosenCardBg
        this.isGameReady = true
        this.isCurrentGameDone = false
        this.selectedItem = null
        this.receivedItems = []
      })
    },

    showNextCard () {
      if (this.totalFlashedCards >= this.maxCards) {
        return
      }
      this.totalFlashedCards++
      this.isCurrentGameDone = false
      this.selectedItem = null
      this.receivedItems = []
      this.cardCombination = null
      this.hasShownCardOnce = true
      setTimeout(() => {
        this.generateCardCombo()
      }, 100)
    },

    readyTheGame () {
      this.isGameReady = true
      this.selectedItem = null
      this.cardCombination = null
      this.winningObject = null
      this.totalFlashedCards = 0
      this.singlePlayerScore = 0
      this.gameUsers.forEach(user => {
        user.totalWins = 0
      })
      if (this.hasShownCardOnce) {
        this.showNextCard()
      }
    },

    generateCardCombo () {
      this.cardCombination = []
      const item1 = this.pickRandom(this.theme.items)
      let remainingItems = this.theme.items.filter(item => item.code !== item1.code)
      const item2 = this.pickRandom(remainingItems)
      remainingItems = remainingItems.filter(item => item.code !== item2.code)
      const option = this.pickRandom(['same', 'diff', 'diff', 'same', 'diff'])
      if (option === 'same') {
        this.cardCombination.push(this.setItemColorFrom(item1, item1, 1))
        this.cardCombination.push(this.setItemColorFrom(item2, this.pickRandom(remainingItems), 2))
        this.winningObject = item1
      } else {
        const item3 = this.pickRandom(remainingItems)
        remainingItems = remainingItems.filter(item => item.code !== item3.code)
        const item4 = this.pickRandom(remainingItems)
        remainingItems = remainingItems.filter(item => item.code !== item4.code)
        this.cardCombination.push(this.setItemColorFrom(item1, item3, 1))
        this.cardCombination.push(this.setItemColorFrom(item2, item4, 2))
        this.winningObject = remainingItems[0]
      }
      this.chosenCardBg = this.pickRandom(this.theme.backgroundRadients)
      this.$socket.emit('game-card-combo', {
        channel: this.currentChannel,
        cardCombo: this.cardCombination,
        winnerCard: this.winningObject,
        chosenCardBg: this.chosenCardBg
      })
    },

    setItemColorFrom (item, colorItem, position) {
      return {
        code: item.code,
        fill1: colorItem.fill1,
        fill2: colorItem.fill2 || colorItem.fill1,
        stroke1: colorItem.stroke1,
        width: position === 2 ? item.width * 1.4 : item.width,
        top: position === 1 ? (item.top + 5) : ((item.top * 1.4) + 80),
        left: position === 1 ? (item.left + 5) : ((item.left * 1.4) + 40)
      }
    },

    getClassForItem (item) {
      if (!this.selectedItem) {
        return null
      }
      return {
        selected: this.selectedItem.code === item.code,
        error: this.isSinglePlayer && this.selectedItem.code === item.code && item.code !== this.winningObject.code,
        success: this.isSinglePlayer && this.isCurrentGameDone && item.code === this.winningObject.code
      }
    }
  },

  mounted () {
    if (this.$route.query.theme) {
      this.theme = this.themes.find(t => t.code === this.$route.query.theme)
    } else {
      this.theme = this.themes[0]
    }
  }
}
</script>

<template>
  <div class="home" @click="closeMenu">
    <div class="nav-menu">
      <router-link to="/">
        <img src="/images/logo.png" width="150">
      </router-link>
    </div>
    <div v-if="isHome" class="list-games">
      <router-link to="/rockpaperscissors" tag="div">
        <div class="img-wrapper">
          <img src="/images/rockpaperscissors/cover.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Rock Paper Scissors</div>
        <div class="detail">
          Rock smashes Scissors. Scissors cut Paper. Paper wraps Rock. Maximum two players can play this game.
        </div>
      </router-link>
      <router-link to="/truthdare" tag="div">
        <div class="img-wrapper">
          <img src="/images/truthdare/cover-page.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Truth or Dare</div>
        <div class="detail">
          A fun party game which involves each player to either answer a
          truth question or do a dare or take a shot. (Max: 10 players)
        </div>
      </router-link>
      <!-- <router-link to="/liar" tag="div">
        <div class="img-wrapper">
          <img src="/images/liar/cover-page.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Liar</div>
        <div class="detail">
          Liar (Like Bluff or Cheat) is a card game where the players aim to get rid of all of their cards.
        </div>
      </router-link> -->
      <!-- <router-link to="/cards" tag="div">
        <div class="img-wrapper">
          <img src="/images/cards/cover-page.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Cards</div>
        <div class="detail">
          Any number of players can play and choose to play with their own rules.
        </div>
      </router-link> -->
      <router-link to="/grab" tag="div">
        <div class="img-wrapper">
          <img src="/images/grab/cover-page.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Grab</div>
        <div class="detail">
          Grab is a reaction game in which quick-wittedness and the right action are crucial.
          It can be played with 1 or more players.
        </div>
      </router-link>
      <router-link to="/mastermind" tag="div">
        <div class="img-wrapper">
          <img src="/images/mastermind/cover-page.svg" alt="Cover page" width="200" >
        </div>
        <div class="title">Mastermind</div>
        <div class="detail">Master Mind is a code-breaking game.</div>
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data () {
    return {
      localUserName: null,
      gameRequestDetails: null,
      isShowMenu: false
    }
  },

  computed: {
    isHome () {
      return this.$route.name === 'Home'
    }
  },

  methods: {
    slideMenu (e) {
      e.stopPropagation()
      this.isShowMenu = !this.isShowMenu
    },

    closeMenu () {
      this.isShowMenu = false
    }
  }
}
</script>

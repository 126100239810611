export default {
  computed: {
    isMobile () {
      return window.matchMedia('only screen and (max-width: 760px)').matches
    },

    playerSize () {
      return this.isMobile ? '20vw' : '10vh'
    }
  },

  methods: {
    getCenterPosition (objSize = 100) {
      return {
        position: 'absolute',
        top: this.isMobile ? `calc(45vw - ${objSize / 2}px)` : `calc(35vh - ${objSize / 2}px)`,
        left: this.isMobile ? `calc(45vw - ${objSize / 2}px)` : `calc(35vh - ${objSize / 2}px)`
      }
    },

    getPlayersAngles (totalPlayers) {
      return this.generateArray(totalPlayers).map(index => {
        return this.getPlayerAngle(index, totalPlayers)
      })
    },

    getPlayerAngle (index, totalPlayers) {
      return 180 - (index * (360 / totalPlayers))
    },

    playerPositionStyle (index, totalPlayers) {
      let circleSize = 35
      let playerSize = 10
      if (this.isMobile) {
        circleSize = 45
        playerSize = 20
      }
      const angle = (index * (360 / totalPlayers))
      const { x, y } = this.getPointsCoord(angle, circleSize)
      return {
        top: (circleSize + y - (playerSize / 2)) + (this.isMobile ? 'vw' : 'vh'),
        left: (circleSize + x - (playerSize / 2)) + (this.isMobile ? 'vw' : 'vh')
      }
    },

    getPointsCoord (angle, radius = 200) {
      const x = radius * Math.sin(Math.PI * 2 * angle / 360)
      const y = radius * Math.cos(Math.PI * 2 * angle / 360)
      return {
        x: Math.round(x * 100) / 100,
        y: Math.round(y * 100) / 100
      }
    }
  }
}

<template>
<div>
  <modal v-if="isEditingUserName" @close="isEditingUserName=false">
    <div slot="main">
      <div class="m-b-xs">
        <label>Call me</label>
      </div>
      <div class="m-b-md">
        <input type="text"
          v-model="localUserName"
          @keydown.enter="updateUserName"
          @change="updateUserName"
          ref="userName">
      </div>
      <div>
        <button class="btn btn-success btn-block" @click="updateUserName">✔ Done</button>
      </div>
    </div>
  </modal>
  <div v-else @click="editUserName" class="current-player-name">
    {{ localUserName }}
    <span class="clickable" >✏️</span>
  </div>
</div>
</template>

<script>
import { actions } from '@/store/constants'
export default {
  name: 'UserNameBox',

  data () {
    return {
      localUserName: null,
      localUserNameCopy: null,
      isEditingUserName: false
    }
  },

  props: {
    channel: {
      type: String,
      required: false
    }
  },

  methods: {
    updateUserName () {
      if (!this.localUserName) {
        this.localUserName = this.localUserNameCopy
        return
      }
      this.localUserNameCopy = this.localUserName
      localStorage.setItem('user', this.localUserName)
      this.$store.dispatch(actions.user.name, this.localUserName).then(() => {
        this.$socket.emit('online_user', {
          name: this.localUserName,
          channel: this.channel
        })
        this.isEditingUserName = false
      }).catch(() => {
        this.localUserName = this.localUserNameCopy
      })
    },

    editUserName () {
      this.isEditingUserName = true
      this.focus()
    },

    focus () {
      setTimeout(() => {
        if (this.$refs.userName) {
          this.$refs.userName.focus()
        }
      }, 100)
    },

    pickRandom (arr) {
      const index = Math.ceil(Math.random() * arr.length - 1)
      return arr[index]
    }
  },

  mounted () {
    this.localUserName = localStorage.getItem('user')
    if (!this.localUserName) {
      this.isEditingUserName = true
      this.focus()
    } else {
      this.updateUserName()
    }
  }
}
</script>
